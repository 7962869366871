import React from 'react';

const IconPRG = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="16.000000pt"
    height="24.000000pt"
    viewBox="0 0 26.000000 34.000000"
    preserveAspectRatio="xMidYMid meet">
    <g
      transform="translate(0.000000,34.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none">
      <path
        d="M12 328 c-8 -8 -12 -51 -12 -135 0 -109 2 -125 19 -140 42 -38 48
-42 59 -31 7 7 17 6 31 -1 13 -7 21 -8 21 -2 0 6 28 11 63 13 l62 3 0 150 0
150 -115 3 c-81 2 -119 -1 -128 -10z m102 -74 c-6 -33 -5 -36 17 -32 18 2 23
8 21 25 -3 24 15 40 30 25 14 -14 -17 -59 -48 -66 -20 -5 -23 -11 -18 -32 6
-21 3 -25 -11 -22 -16 2 -18 13 -18 66 1 77 0 72 18 72 13 0 15 -7 9 -36z
m116 -169 c0 -23 -3 -25 -49 -25 -40 0 -50 3 -54 20 -3 14 -14 20 -32 20 -18
0 -29 -6 -32 -20 -7 -25 -23 -26 -23 -2 0 29 8 32 102 32 87 0 88 0 88 -25z"
      />
    </g>
  </svg>
);

export default IconPRG;
